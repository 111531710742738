<template>
  <v-card class="px-6 py-4" color="secondary" min-height="100%">
    <v-card-title>
      <div class="d-inline-flex my-3">
        <v-icon class="text-h4 white--text mr-2">mdi-account</v-icon>
        <h4 class="text-h4 white--text font-weight-bold">My Profile</h4>
      </div>
    </v-card-title>
    <div class="mb-5">
      <v-row>
        <v-col class="px-md-4" cols="12" lg="5" md="5">
          <v-container fill-height fluid>
            <v-row align="center" class="lighten-5" justify="center">
              <v-avatar class="mt-6" size="145">
                <img :src="img" alt="Profile Picture"/>
              </v-avatar>
<!--              <div class="d-flex justify-center">-->
<!--                <v-rating length="5" readonly size="100%"></v-rating>-->
<!--              </div>-->
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" lg="7" md="7">
          <v-card class="pa-4" height="100%">
            <v-row>
              <v-col cols="12">
                <h5 class="text-h5 font-weight-bold">
                  {{ currentUser.usersUid }}
                </h5>
                <h6 class="text-subtitle-1 font-weight-bold mb-4">
                  {{ currentUser.usersCompany }}
                </h6>
                <p class="text-body-2">
                  {{ currentUser.usersEmail }}
                </p>
                <p class="text-body-2">
                  {{ currentUser.usersPhone }}
                </p>
                <p class="text-body-2">
                  {{ currentUser.usersDesc }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-btn :block="$vuetify.breakpoint.mobile" :to="{name: 'Account'}" color="primary" width="10vw">Edit Profile
      </v-btn>
    </div>
  </v-card>
  <!--  </div>-->
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      img: "",
    };
  },
  computed: {
    ...mapState(["baseURL"]),
    ...mapState('user', ["currentUser"]),
  },
  mounted() {
    this.img =
        this.baseURL +
        "/storage/pfps/" +
        this.currentUser.usersPfp;
  },
  methods: {},
};
</script>