<template>
  <div>
    <!-- Mobile -->
    <v-row v-if="$vuetify.breakpoint.mobile" class="secondary pa-8" no-gutters>
      <v-col>
        <div class="d-inline-flex my-3">
          <v-icon class="text-h4 white--text mr-2">mdi-wrench</v-icon>
          <h4 class="text-h4 white--text font-weight-bold">My Parts</h4>
        </div>
        <p class="text-h6 white--text">{{ amount }} Listed</p>
        <p class="text-h6 white--text">{{ remaining }} Remaining</p>
        <v-btn :to="{name: 'NewPart'}" block class="mb-4" color="primary">Sell a new part</v-btn>

        <div v-if="hasParts">
          <v-row>
            <v-col cols="6">
              <PartIcon :part="parts[0]" class="my-2" height="150px"/>
              <PartIcon :part="parts[1]" class="my-2" height="150px"/>
            </v-col>
            <v-col cols="6">
              <PartIcon :part="parts[2]" class="my-2" height="150px"/>
              <v-btn
                  :to="{ name: 'PartsList' }"
                  block
                  class="primary--text my-2"
                  height="150px"
              >View more
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn
              >
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <h3 class="mt-16 text-h3 white--text font-weight-bold">
            You currently have no parts
          </h3>
        </div>
      </v-col>
    </v-row>
    <!-- Desktop -->
    <v-row v-else class="secondary px-16">
      <v-col class="pa-8 px-16" cols="4">
        <div class="d-inline-flex my-3">
          <v-icon class="text-h4 white--text mr-2">mdi-wrench</v-icon>
          <h4 class="text-h4 white--text font-weight-bold">My Parts</h4>
        </div>
        <p class="text-h6 white--text">{{ amount }} Listed</p>
        <p class="text-h6 white--text">{{ remaining }} Remaining</p>
        <v-btn :to="{name: 'NewPart'}" block color="primary">Sell a new part</v-btn>
      </v-col>
      <v-col v-if="hasParts" class="pa-8" cols="8">
        <v-row>
          <v-col cols="3">
            <PartIcon :part="parts[0]" class="my-2" height="190px"/>
          </v-col>
          <v-col cols="3">
            <PartIcon :part="parts[1]" class="my-2" height="190px"/>
          </v-col>
          <v-col cols="3">
            <PartIcon :part="parts[2]" class="my-2" height="190px"/>
          </v-col>
          <v-col cols="3">
            <v-btn
                :to="{ name: 'PartsList' }"
                block
                class="primary--text my-2"
                height="190px"
            >View more
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else class="pa-8" cols="8">
        <h3 class="mt-16 text-h3 white--text font-weight-bold">
          You currently have no parts
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PartIcon from "@/components/landing/PartIcon.vue";
import {mapActions, mapState} from 'vuex';

export default {
  components: {PartIcon},
  data() {
    return {
      hasParts: false,
      amount: 0,
      remaining: 0,
      totalParts: 0,
      parts: [],
    };
  },
  computed: mapState('user', ["currentUser"]),
  mounted() {
    this.getParts();
    this.getPartCount();
  },
  methods: {
    ...mapActions('data', ["postData"]),
    async getParts() {
      let response = await this.postData({
        url: "fetchUserParts",
        data: {usersID: this.currentUser.usersID},
      });
      //console.log(response);
      if (response["error"] === "none") {
        if (response["parts"] === "none") {
          this.hasParts = false;
        } else {
          this.hasParts = true;
          this.parts = response["parts"];
        }
      } else if (response["error"] !== "none") {
        console.log(response["error"]);
      }
    },
    async getPartCount() {
      let response = await this.postData({
        url: "fetchUserTier",
        data: {usersID: this.currentUser.usersID},
      });
      //console.log(response);
      if (response["error"] === "none") {
        this.amount = response["usersPartsAmount"];
        switch (this.currentUser.usersTier) {
          case 0:
            this.totalParts = 50;
            break;
          case 1:
            this.totalParts = 200;
            break;
          case 2:
            this.totalParts = 500;
            break;
          case 3:
            this.totalParts = 1000;
            break;
          case 4:
            this.totalParts = 10000;
            break;
          default:
            console.log("Error: User's Tier is unknown ln 70")
            break;
        }
        this.remaining = this.totalParts - this.amount;
      } else if (response["error"] !== "none") {
        console.log(response["error"]);
      }
    },
  },
};
</script>

<style>
</style>