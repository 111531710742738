<template>
  <div>
    <v-container>
      <v-row align="stretch" class="py-8">
        <v-col cols="12" lg="6" md="6">
          <ProfileCard/>
        </v-col>
        <v-col cols="12" lg="6" md="6">
          <SubCard color="secondary"/>
        </v-col>
      </v-row>
    </v-container>
    <MyPartsView/>
<!--    <Refferal/>-->
  </div>
</template>

<script>
import ProfileCard from "@/components/dashboard/ProfileCard.vue";
import SubCard from "@/components/dashboard/SubCard.vue";
import MyPartsView from "@/components/dashboard/MyPartsView.vue";
import {mapActions} from 'vuex';
import Refferal from "@/components/dashboard/Referral";

export default {
  metaInfo: {
    title: "Dashboard",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  components: {ProfileCard, SubCard, MyPartsView},
  data() {
    return {};
  },
  created() {
    this.pwdCheckCall();
    this.getUser();
  },
  methods: {
    ...mapActions('alert', ["alerts"]),
    ...mapActions('user', ["pwdCheck", "getUser"]),
    async pwdCheckCall() {
      let response = await this.pwdCheck();
      if (response["error"] === "userNotFound") {
        this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        await this.$router.push({name: "Login"});
        this.alerts({
          type: "error",
          msg: "You must be logged in to access this page.",
        });
      }
    },
  },
};
</script>
