<template>
  <div class="my-12">
    <!-- Desktop -->
    <v-row class="secondary px-16">
      <v-col class="pa-8 px-16" cols="4">
        <div class="d-inline-flex my-3">
          <h4 class="text-h4 white--text font-weight-bold">Refferal</h4>
        </div>
        <p class="body-1 white--text">Here you can give others a temporary membership via giving them your referal
          code.</p>
      </v-col>
      <v-col class="pa-8 px-16">
        <v-row>
          <v-col cols="6">
            <p class="text-h5 white--text">Your referral code is: </p>
            <p v-if="currentUser.usersReferral !== '' || currentUser.usersReferral !== undefined"
               class="font-weight-bold white--text text-h5">{{ currentUser.usersReferral }}</p>
            <p v-else class="font-weight-bold white--text text-h5">Code Unavailable</p>
          </v-col>
          <v-col cols="6">
            <p class="text-h5 white--text">Enter a referral code:</p>
            <v-text-field :disabled="currentUser.usersReferred"
                          :placeholder="currentUser.usersReferred ? 'You have already used a referral code' : 'Enter referral code here'"
                          solo/>
            <v-btn color="primary" @click="submitReferal">Submit</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PartIcon from "@/components/landing/PartIcon.vue";
import {mapState} from 'vuex';

export default {
  name: "Refferal",
  components: {PartIcon},
  data() {
    return {};
  },
  computed: mapState('user', ["currentUser"]),
  mounted() {
  },
  methods: {
    async submitReferal() {
      // TODO Add code reference to PHP script for referral checking
    }
  },
};
</script>

<style>
</style>